<template>
  <section class="hero is-fullheight">
    <ModalStatusSale
      v-if="activeModalStatusSale"
      :active="activeModalStatusSale"
      :loading="salesLoaderSpinner"
      :statusId="statusId"
      @close-modal="activeModalStatusSale = false"
      :statusQuotationList="salesStatusList"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <status-sale
              @set-moda-sale-status="activeModalStatusSale = true"
              :status-id="statusId"
              :status-name="statusName"
            />
          </div>
          <div class="column is-12">
            <accordion-sale-detail />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utilities from '@/extras/utilities'

export default {
  name: 'SaleMainDetail',
  components: {
    StatusSale: () => import('@/components/Sales/Detail/StatusSale.vue'),
    AccordionSaleDetail: () => import('@/components/Sales/Detail/AccordionSaleDetail.vue'),
    ModalStatusSale: () => import('@/components/Sales/Detail/ModalStatusSale.vue')
  },
  computed: {
    ...mapGetters(['salesLoaderSpinner', 'salesStatusList', 'actualSale'])
  },
  data () {
    return {
      activeModalStatusSale: false,
      statusId: 0,
      statusName: ''
    }
  },
  async beforeMount () {
    if (!this.salesStatusList.length) {
      await this.getSalesStatus()
    }
    await this.getSaleDetail(this.$route.params.saleId)
  },
  methods: {
    ...mapActions(['getSalesStatus', 'getSaleDetail'])
  },
  watch: {
    actualSale: {
      deep: true,
      handler (newData) {
        if (newData) {
          this.statusId = utilities.objectValidate(newData, 'sale.status_id', 0)
          this.statusName = utilities.objectValidate(newData, 'sale.status_name', '')
        }
      }
    }
  }
}
</script>
